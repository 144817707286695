import React from "react";
import { Routes, Route } from "react-router-dom";
import Users from "./Users";
import Tags from "./Tags";
import Home from "./Home";
import NewQuestion from "./Question/NewQuestion";
import RightPanel from "./RightPanel";
import Question from "./Question/Question";
import { Box } from "@mui/material";
import Shorticles from "./Shorticles";
import NewShorticle from "./Shorticle/NewShorticle";
import MyQuestions from "./MyQuestions";
import Questions from "./Questions";

function AppRouter() {

    return (
        <Box className="d-flex">
            <Box sx={{ flexGrow: 1 }}>
                <Routes>
                    <Route exact path="/" element={<Home />}></Route>
                    <Route path="/questions" Component={Questions}></Route>
                    <Route path="/users" Component={Users}></Route>
                    <Route path="/tags" Component={Tags}></Route>
                    <Route path="/newQuestion" Component={NewQuestion}></Route>
                    <Route path="/question/:questionId" Component={Question}></Route>
                    <Route path="/shorticles" Component={Shorticles}></Route>
                    <Route path="/shorticles/:shorticleId" Component={Shorticles}></Route>
                    <Route path="/newShorticle" Component={NewShorticle}></Route>
                    <Route path="/myQuestions" Component={MyQuestions}></Route>
                </Routes>
            </Box>

            <RightPanel></RightPanel>

        </Box>
    );
}

export default AppRouter;
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { Typography, IconButton } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

export default function QuestionLikeUnlike(props) {
    const questionId = props.questionId;
    const [likesCount, setLikesCount] = useState(props.likesCount);
    //const getNewCount = props.getNewCount;
    const { DataAPI, currentUser } = useAuth();
    const [isLiked, setIsLiked] = useState(false);
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        if (currentUser) {
            DataAPI.IsQuestionLikedByUser(questionId, currentUser.uid).then((res) => {
                setIsLiked(res);
            });
        }
    }, [])

    function onLikeUnlike() {
        try {
            setDisable(true);
            if (isLiked) {
                DataAPI.unLikeQuestion(questionId, currentUser.uid).then(() => {
                    setIsLiked(false);
                    //getNewCount();
                    setLikesCount(likesCount - 1);
                    setDisable(false);
                })

            } else {
                DataAPI.likeQuestion(questionId, currentUser.uid).then(() => {
                    setIsLiked(true);
                    //getNewCount();
                    setLikesCount(likesCount + 1);
                    setDisable(false);
                })
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <IconButton size='small' onClick={onLikeUnlike} disabled={currentUser ? (disable?true:false) : true}>
            {isLiked ?
                <ThumbUpIcon fontSize='small'></ThumbUpIcon>
                : <ThumbUpOffAltIcon fontSize='small'></ThumbUpOffAltIcon>
            }
            <Typography variant='caption' ml={0.5}>{likesCount}</Typography>
        </IconButton>
    )
}

import React, { useEffect, useRef, useState } from 'react';
import CoronavirusOutlinedIcon from '@mui/icons-material/CoronavirusOutlined';
import { Badge, Box, Divider, IconButton, InputLabel, Snackbar, SpeedDial, SpeedDialAction, SwipeableDrawer, TextField, Typography } from '@mui/material';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import SendIcon from '@mui/icons-material/Send';
import { useAuth } from '../../context/AuthContext';
import TimeAgo from '../Utilities/TimeAgo';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

export default function CommentsLikes(props) {

    const [showComments, setShowComments] = useState(false);
    const newComment = useRef();
    const { DataAPI, currentUser } = useAuth();
    const [comments, setComments] = useState([]);
    const [addLoadMore, setAddLoadMore] = useState(false);
    const loadMore = useRef();
    const observerRef = useRef(null);
    const [liked, setLiked] = useState(false);
    const [sdOpen, SetsdOpen] = useState(false);
    const [onceOpened, setOnceOpened] = useState(false);
    const [likesCount, setLikesCount] = useState(0);
    const [notify, setNotify] = useState(false);
    const [notifyMessage, setNotifyMessage] = useState("");
    const [allCmtsLoaded, setAllCmtsLoaded] = useState(false);

    useEffect(() => {
        if (addLoadMore) {
            if (!allCmtsLoaded)
                addLoarMoreObserver();
            setAddLoadMore(false);
        }
    }, [addLoadMore])

    function addLoarMoreObserver() {
        let options = {
            root: null,
            rootMargin: "0px",
            threshold: 0,
        };
        let observer = new IntersectionObserver(loadMoreComments, options);
        observer.observe(loadMore.current);
        observerRef.current = observer;
    }

    function displayComments() {
        setShowComments(true);
        if (comments.length === 0) {
            addLoarMoreObserver();
        }
    }

    async function loadMoreComments(entries, observer) {
        let isIntersecting = true;
        entries.forEach((entry) => {
            if (!entry.isIntersecting) {
                isIntersecting = false;
            }
        })
        if (!isIntersecting)
            return;
        const after = comments.length > 0 ? comments[comments.length - 1].date : 0;
        const commts = await DataAPI.getComments(props.objid, after);
        if (commts.length > 0) {
            setComments([...comments, ...commts]);
            setAddLoadMore(true);
        } else {
            setAllCmtsLoaded(true);
        }
        observer.disconnect();
    }

    function share(e) {
        try {
            const shareData = {
                url: props.shareurl,
                text: props.title,
                title: props.title
            }
            if (navigator.canShare(shareData))
                navigator.share(shareData);
        } catch (e) {
            console.log(e);
        }
    }

    function addComment() {
        try {
            if (newComment.current.value.length < 1 || newComment.current.value.length > 512)
                return;
            const commentObj = {
                userId: currentUser.uid,
                comment: newComment.current.value,
                date: new Date().getTime(),
                userName: currentUser.displayName
            }
            DataAPI.addComment(props.objid, commentObj).then((res) => {
                commentObj.id = commentObj.date;
                setComments([commentObj, ...comments]);
                newComment.current.value = "";
                observerRef.current.disconnect();
                // load more only if comments.length is > number of comments fetch in a roundtrip
                setAddLoadMore(true);
            })
        } catch (e) {
            console.log(e);
        }
    }

    function deleteComment(commentId, index) {
        if (!window.confirm('are you sure, you want to delete the comment?'))
            return;
        DataAPI.deleteComment(props.objid, commentId).then(() => {
            const cmts = [];
            comments.forEach((val, i) => {
                if (index === i)
                    return;
                cmts.push(val);
            })
            setComments(cmts);
        })
    }

    const commentsUI =
        <SwipeableDrawer
            anchor={'bottom'}
            disableSwipeToOpen
            open={showComments}
            onClose={() => { setShowComments(false) }}
            onOpen={() => { setShowComments(true) }}
            sx={{
                '& .MuiDrawer-paper': { borderTopLeftRadius: 12, borderTopRightRadius: 12, overflow: 'hidden', maxWidth: '500px' }
            }}
        >
            <Box key='comHeader' sx={{ paddingX: 2, pt: 1, display: 'flex', alignItems: 'center' }}>
                <Typography variant='h6'>Comments</Typography>
                <IconButton
                    sx={{ ml: 'auto' }}
                    color="inherit"
                    onClick={() => { setShowComments(false) }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <Divider sx={{ marginX: -2 }} />
            <Box key='comBody' sx={{ height: '60vh', px: 2, py: 2, overflow: 'auto' }}>
                {currentUser && <Box key='newCom' sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                    <TextField placeholder='Add a comment' multiline inputRef={newComment} fullWidth size='small' maxLength={512} maxRows={3}></TextField>
                    <IconButton onClick={addComment}><SendIcon /></IconButton>
                </Box>}
                {currentUser && <Divider sx={{ marginX: -2, my: 2 }} />}

                {comments.map((comment, i) => {
                    return <Box key={comment.id}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography component={InputLabel} variant='caption' sx={{ mr: 2 }}>{comment.userName}</Typography>
                            <Typography component={InputLabel} variant='caption'>{<TimeAgo milliSeconds={comment.date}></TimeAgo>}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                InputProps={{ readOnly: true, value: comment.comment }}
                                sx={{ '& .MuiInputBase-root': { p: 0 }, '& .MuiInputBase-root fieldset': { display: 'none' } }}
                                multiline
                                fullWidth
                                size='small' />
                            {currentUser && comment.userId === currentUser.uid &&
                                <IconButton sx={{ ml: 'auto', p: 0 }}
                                    size='small'
                                    onClick={() => { deleteComment(comment.id, i) }}
                                >
                                    <DeleteOutlineOutlinedIcon />
                                </IconButton>
                            }
                        </Box>
                        <Divider sx={{ marginX: -2, my: 2 }} />
                    </Box>
                })}
                {
                    comments.length === 0 && <Box>No comments</Box>
                }
                <div ref={loadMore}></div>
            </Box>
        </SwipeableDrawer>

    async function toggleLike() {
        try {
            if (!currentUser) {
                setNotify(true);
                setNotifyMessage("Please login to like and comment");
                return;
            }
            if (liked)
                DataAPI.unlike(props.objid, currentUser.uid).then(() => {
                    setLiked(false);
                    setLikesCount(likesCount - 1);
                })
            else
                DataAPI.like(props.objid, currentUser.uid).then(() => {
                    setLiked(true);
                    setLikesCount(likesCount + 1);
                })
        } catch (e) {
            console.log(e);
        }
    }

    async function isLikedByUser() {
        try {
            DataAPI.isLikedByUser(props.objid, currentUser.uid).then((res) => {
                setLiked(res);
            })
        } catch (e) {
            console.log(e);
        }
    }

    function getlikesCount() {
        try {
            DataAPI.getlikesCount(props.objid).then((res) => {
                setLikesCount(res);
            })
        } catch (e) {
            console.log(e);
        }
    }

    function speedDialOpen() {
        if (!onceOpened) {
            if (currentUser)
                isLikedByUser();
            getlikesCount();
            setOnceOpened(true);
        }
    }

    return (
        <>
            <SpeedDial
                open={sdOpen}
                onMouseEnter={() => { SetsdOpen(true) }}
                onClick={() => { SetsdOpen(!sdOpen) }}
                ariaLabel="Actions"
                direction='up'
                transitionDuration={0}
                icon={<CoronavirusOutlinedIcon />}
                {...props}
                onOpen={speedDialOpen}
            >
                <SpeedDialAction
                    key={'share'}
                    icon={<ShareOutlinedIcon />}
                    tooltipTitle={'Share'}
                    onClick={share}
                />
                <SpeedDialAction
                    key={'like'}
                    icon={liked ? <Badge showZero max={999} badgeContent={likesCount}><ThumbUpIcon /></Badge> : <Badge max={999} showZero badgeContent={likesCount}><ThumbUpOutlinedIcon /></Badge>}
                    tooltipTitle={'Like'}
                    onClick={toggleLike}
                //FabProps={{disabled:!currentUser}}
                />
                <SpeedDialAction
                    key={'comment'}
                    icon={<InsertCommentOutlinedIcon />}
                    tooltipTitle={'Comment'}
                    onClick={() => { displayComments() }}
                />
            </SpeedDial>
            {commentsUI}
            <Snackbar
                open={notify}
                autoHideDuration={3000}
                onClose={() => { setNotify(false) }}
                message={notifyMessage}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            />
        </>
    )
}
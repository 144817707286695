import React from 'react'
import { useAuth } from '../../context/AuthContext';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton, Typography, ScopedCssBaseline, Divider, IconButton, Box } from '@mui/material';
import RichTextEditorReadOnly from '../Utilities/RichTextEditorReadOnly';
import QuestionLikeUnlike from './QuestionLikeUnlike';
import TimeAgo from '../Utilities/TimeAgo';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Comments from './Comments';
import Responses from './Response/Responses';
import ShareIcon from '@mui/icons-material/Share';
import QTitleEdit from './QTitleEdit';
import QDetailsEdit from './QDetailsEdit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useNavigate } from 'react-router-dom';

export default function Question() {
  const params = useParams();
  const questionId = params.questionId;
  const { DataAPI, currentUser, adminUser } = useAuth();
  const [question, setQuestion] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!question) {
      getQuestion();
    };
  }, [])

  if (question) {
    if (question.id !== questionId)
      getQuestion();
  }

  function getQuestion() {
    try {
      DataAPI.getQuestion(questionId).then((q) => {
        setQuestion(q);
        let tagString = '';
        q.tags.forEach((tag) => {
          tagString = tagString + "#" + tag + " ";
        })
        document.title = q.title + " " + tagString;
      })
    } catch (e) {
      console.log(e);
    }
  }

  function share(e) {
    try {
      const shareData = {
        url: '/questions/' + question.id,
        text: question.title,
        title: question.title
      }
      if (navigator.canShare(shareData))
        navigator.share(shareData);
    } catch (e) {
      console.log(e);
    }
  }

  function deleteQ() {
    try {
      if (!window.confirm('are you sure, you want to delete the question?'))
        return;
      DataAPI.deleteQ(question.id);
      setQuestion(null);
      navigate("/questions");
    } catch (e) {
      console.log(e);
    }
  }

  return (
    question ?
      <ScopedCssBaseline key={questionId} className='w-100'>

        <Typography sx={{ display: 'inline' }} variant='h5'>
          {question.title}
          {currentUser && (currentUser.uid === question.userId || adminUser) &&
            <QTitleEdit question={question} onUpdate={setQuestion} />}
        </Typography>

        <div className='w-100 d-flex align-items-center' style={{ justifyContent: 'space-between' }}>
          <div className='d-flex flex-wrap'>
            <Typography variant='caption' mr={2}><AccessTimeIcon fontSize='20' />{" "}<TimeAgo milliSeconds={question.date}></TimeAgo></Typography>
            <Typography variant='caption' mr={2}><PersonOutlineIcon fontSize='20' />{" " + question.userName}</Typography>
          </div>
          <div className='d-flex align-items-center'>
            <QuestionLikeUnlike questionId={questionId} likesCount={question.likes} getNewCount={getQuestion} />
            <IconButton onClick={share} size='small'>
              <ShareIcon fontSize='small'></ShareIcon>
            </IconButton>
            <IconButton onClick={deleteQ} size='small'>
              <DeleteOutlineOutlinedIcon fontSize='small'></DeleteOutlineOutlinedIcon>
            </IconButton>
          </div>
        </div>

        <Divider variant='fullWidth' textAlign='left' component={'div'} className='my-2'></Divider>

        {
          //<div>Tags : {question.tags.map((i) => i + ", ")}</div>
        }
        <Box sx={{ display: 'flex' }}>
          <RichTextEditorReadOnly rawContent={question.detail}></RichTextEditorReadOnly>
          {currentUser && (currentUser.uid === question.userId || adminUser) &&
            <QDetailsEdit question={question} onUpdate={setQuestion} />}
        </Box>


        <Comments commentsFor="question" commentsForObj={question}></Comments>
        <Responses questionId={questionId}></Responses>

      </ScopedCssBaseline>

      : <ScopedCssBaseline className='w-100'>
        Loading...
        <Skeleton></Skeleton>
        <Skeleton animation="wave"></Skeleton>
        <Skeleton animation="wave"></Skeleton>
      </ScopedCssBaseline>
  )
}
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import FeedIcon from '@mui/icons-material/Feed';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { useAuth } from '../context/AuthContext';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HelpIcon from '@mui/icons-material/Help';

export default function AppMenu() {
    const navigate = useNavigate();
    const location = useLocation();
    const { currentUser } = useAuth();

    const currentPath = location.pathname;

    const homeIcon = currentPath === "/" ? <HomeIcon sx={{ color: 'black' }} /> : <HomeOutlinedIcon />;
    const questionsIcon = currentPath === "/questions" ? <HelpIcon sx={{ color: 'black' }} /> : <HelpOutlineOutlinedIcon />;
    const tagsIcon = currentPath === "/tags" ? <LocalOfferIcon sx={{ color: 'black' }} /> : <LocalOfferOutlinedIcon />;
    const usersIcon = currentPath === "/users" ? <PeopleIcon sx={{ color: 'black' }} /> : <PeopleOutlinedIcon />;
    const shorticlesIcon = currentPath === "/shorticles" ? <FeedIcon sx={{ color: 'black' }} /> : <FeedOutlinedIcon />;
    const myQuestionsIcon = currentPath === "/myQuestions" ? <HelpIcon sx={{ color: 'black' }} /> : <HelpOutlineOutlinedIcon />;

    const homeText = currentPath === "/" ? <b>Home</b> : 'Home';
    const questionsText = currentPath === "/questions" ? <b>Questions</b> : 'Questions';
    const usersText = currentPath === "/users" ? <b>Users</b> : 'Users';
    const tagsText = currentPath === "/tags" ? <b>Tags</b> : 'Tags';
    const shorticlesText = currentPath === "/shorticles" ? <b>Shorticles</b> : 'Shorticles';
    const myQuestionsText = currentPath === "/myQuestions" ? <b>Questions</b> : 'Questions';

    const onNavClick = (to) => {
        //e.preventDefault();
        navigate(to);
    }

    return (
        <Box>
            <Divider />
            <List sx={{ paddingX: 1 }}>

                <ListItem key={'home'} disablePadding>
                    <ListItemButton onClick={() => { onNavClick('/') }}>
                        <ListItemIcon>
                            {homeIcon}
                        </ListItemIcon>
                        <ListItemText primary={homeText} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'questions'} disablePadding>
                    <ListItemButton onClick={() => { onNavClick('/questions') }}>
                        <ListItemIcon>
                            {questionsIcon}
                        </ListItemIcon>
                        <ListItemText primary={questionsText} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'shorticles'} disablePadding>
                    <ListItemButton onClick={() => { onNavClick('/shorticles') }}>
                        <ListItemIcon>
                            {shorticlesIcon}
                        </ListItemIcon>
                        <ListItemText primary={shorticlesText} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'tags'} disablePadding>
                    <ListItemButton onClick={() => { onNavClick('/tags') }}>
                        <ListItemIcon>
                            {tagsIcon}
                        </ListItemIcon>
                        <ListItemText primary={tagsText} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'users'} disablePadding>
                    <ListItemButton onClick={() => { onNavClick('/users') }}>
                        <ListItemIcon>
                            {usersIcon}
                        </ListItemIcon>
                        <ListItemText primary={usersText} />
                    </ListItemButton>
                </ListItem>

                {currentUser && <Divider>my..</Divider>}
                {currentUser &&
                <ListItem key={'myQuestions'} disablePadding>
                    <ListItemButton onClick={() => { onNavClick('/myQuestions') }}>
                        <ListItemIcon>
                            {myQuestionsIcon}
                        </ListItemIcon>
                        <ListItemText primary={myQuestionsText} />
                    </ListItemButton>
                </ListItem>}

            </List>

        </Box>
    )
}


import React from 'react';
import { useParams } from 'react-router-dom';
import GetShorticles from './Shorticle/GetShorticles';


export default function Shorticles() {
    const params = useParams();
    const shorticleId = params.shorticleId ? parseInt(params.shorticleId) : 0;
  return (
    <div key={shorticleId}>
        <GetShorticles shorticleId={shorticleId}/>
    </div>
  )
}
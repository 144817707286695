import React from 'react'

export default function TimeAgo(props) {
    const mSeconds = Number.isInteger(props.milliSeconds) ? props.milliSeconds:0;

    function getTimeAgo(){
        let remainingSecs = (new Date()).getTime()-mSeconds,years=0, months=0, days=0, hours=0, minutes=0;
        years = Math.floor(remainingSecs/(360*24*60*60*1000));
        remainingSecs = remainingSecs - years*360*24*60*60*1000;
        months = Math.floor(remainingSecs/(30*24*60*60*1000));
        remainingSecs = remainingSecs - months*30*24*60*60*1000;
        days = Math.floor(remainingSecs/(24*60*60*1000));
        remainingSecs = remainingSecs - days*24*60*60*1000;
        hours = Math.floor(remainingSecs/(60*60*1000));
        remainingSecs = remainingSecs - hours*60*60*1000;
        minutes = Math.floor(remainingSecs/(60*1000));

        let timeAgo = "";
        if(years > 0){
            timeAgo = months > 0 ? 
            years + (years > 1?" years " : " year ") + months + (months > 1?" months ago":" month ago")
            : years + (years > 1?" years ago":" year ago");
        }else if(months > 0){
            timeAgo = days > 0 ? 
            months + (months > 1 ? " months " : " month") + days + (days > 1 ? " days ago" : " day ago")
            : months + " months ago";
        }else if(days > 0){
            timeAgo = hours > 0 ? 
            days + (days > 1 ? " days " : " day ") + hours + (hours > 1 ? " hours ago" : " hour ago")
            : days + " days ago";
        }else if(hours > 0){
            timeAgo = minutes > 0 ? 
            hours + (hours > 1 ? " hours " : " hour ") + minutes + (minutes > 1 ? " minutes ago" : " minute ago")
            : hours + " hours ago";
        }else if(minutes > 0){
            timeAgo = minutes + (minutes > 1 ? " minutes ago" : " minute ago");
        }else{
            timeAgo = "now";
        }
        
        return timeAgo;
    }

  return (
    <span>{getTimeAgo()}</span>
  )
}

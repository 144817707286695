//import { db } from '../firebase';
import { responsesDB as db } from "../firebaseResponses";
import { doc, getDoc, setDoc, deleteDoc, updateDoc, increment, serverTimestamp, arrayUnion, query, collection, limit, getDocs, arrayRemove, deleteField } from "firebase/firestore";

export const responseAPI = {
    async addResponse(qid, responseObj) {
        responseObj.fbDate = serverTimestamp();
        const id = responseObj.date;
        await setDoc(doc(db, 'questions', qid, 'responses', id + ""), responseObj);
        return id;
    },
    async updateResponse(qid, id, response) {
        await updateDoc(doc(db, 'questions', qid, 'responses', id), { response: response });
        return true;
    },
    async getResponses(qid) {
        const resRef = collection(db, 'questions', qid, 'responses')
        const q = query(resRef, limit(process.env.REACT_APP_RESPONSES_LIMIT));
        const qSnapshot = await getDocs(q);
        const responses = [];
        qSnapshot.forEach((doc) => {
            const response = doc.data();
            response.id = doc.id;
            responses.push(response);
        });
        return responses;
    },
    async getResponse(qid, rid) {
        const resSnapshot = await getDoc(doc(db, 'questions', qid, 'responses', rid + ""));
        const res = resSnapshot.exists() ? resSnapshot.data() : null;
        if (res)
            res.id = resSnapshot.id;
        return res;
    },
    async delete(qid, rid) {
        return deleteDoc(doc(db, 'questions', qid, 'responses', rid));
    },
    async deleteAllResponses(qid) {
        return setDoc(doc(db, 'questions', qid), { delete: true });
    },
    async IsResponseLikedByUser(qid, rid, uid) {
        const resSnapshot = await getDoc(doc(db, 'questions', qid, 'responses', rid, 'likes', uid));
        return resSnapshot.exists() ? true : false;
    },
    async likeResponse(qid, rid, uid) {
        await setDoc(doc(db, 'questions', qid, 'responses', rid, 'likes', uid), { uId: uid, fbDate: serverTimestamp() });
        await updateDoc(doc(db, 'questions', qid, 'responses', rid), { likes: increment(1) });
    },
    async unLikeResponse(qid, rid, uid) {
        await deleteDoc(doc(db, 'questions', qid, 'responses', rid, 'likes', uid));
        await updateDoc(doc(db, 'questions', qid, 'responses', rid), { likes: increment(-1) });
    },
    async getComments_Response(qid, rid) {
        const resp = await this.getResponse(qid, rid);
        return resp && resp.comments;
    },
    async addComment_Response(qid, rid, comment) {
        await updateDoc(doc(db, "questions", qid, "responses", rid), { comments: arrayUnion(comment) })
    },
    async deleteRComment(qid, rid, comment) {
        await updateDoc(doc(db, "questions", qid, "responses", rid), { comments: arrayRemove(comment) });
    },
}
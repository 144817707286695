// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD8iFBEFnnfN2XQcPJ5W1RAHrvbi_vLaDU",
  authDomain: "rnfcomments-1fb69.firebaseapp.com",
  projectId: "rnfcomments-1fb69",
  storageBucket: "rnfcomments-1fb69.appspot.com",
  messagingSenderId: "945464006333",
  appId: "1:945464006333:web:075d17b7f6a5fdb5e449d4",
  measurementId: "G-4ZY2DQ5PPR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig,"comments");
export const commentsDB = getFirestore(app);
import React, { useRef, useState } from 'react';
import { Form, Card } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import "draft-js/dist/Draft.css";
import RichTextEditor from '../Utilities/RichTextEditor';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { tags as tagsList } from '../../StaticData/Tags';

export default function NewQuestion() {
  const title = useRef();
  const [tags, setTags] = useState([]);
  let content = useRef();
  function setContent(c) {
    content = c;
  }
  const { currentUser, DataAPI } = useAuth();
  const navigate = useNavigate();
  const [titleError, setTitleError] = useState();
  const [contentError, setContentError] = useState();

  async function handleSubmit(e) {
    e.preventDefault();
    if (!isValidQuestion(title.current.value, content)) {
      return;
    }
    const question = {
      title: title.current.value,
      tags: tags.map(tag => tag.label),
      detail: content,
      date: (new Date()).getTime(),
      userId: currentUser.uid,
      userName: currentUser.displayName,
      likes: 0,
      responses: 0
    }
    const qid = await DataAPI.addNewQuestion(question);
    navigate("/question/" + qid);
  }

  function isValidQuestion(shortDesc, qDetail) {
    let retValue = true;
    setTitleError(null);
    setContentError(null);
    if (!shortDesc || shortDesc.length > process.env.REACT_APP_QUESTION_DESC_LENGTH) {
      setTitleError('Max length allowed is ' + process.env.REACT_APP_QUESTION_DESC_LENGTH);
      retValue = false;
    }
    if (!content || !content.blocks || (content.blocks.length === 1 && (content.blocks[0].text === "" || content.blocks[0].text == null))) {
      setContentError('Enter details');
      retValue = false;
    } else if (!content || JSON.stringify(content).length > process.env.REACT_APP_QUESTION_LENGTH) {
      setContentError('Exceeds max length allowed, reduce content to ' + Math.floor((process.env.REACT_APP_QUESTION_LENGTH * 100 / JSON.stringify(content).length)) + '% of current');
      retValue = false;
    }
    return retValue;
  }

  function onTagChange(e, newValue, reason) {
    if (newValue.length > 3) {
      newValue.shift();
    }
    setTags(newValue);
  }

  return (
    <div className='w-100 new-qstn-root'>
      <h4>Ask question</h4>
      <Typography variant='body2' mb={2}>
        A question in mind, got stuck, need help, looking for a better solution or.... Ask it here, tag it with appropriate topics
      </Typography>
      <Form onSubmit={handleSubmit}>
        <Card className='shadow-lg bg-white rounded mb-2'>
          <Card.Body>
            <Card.Title>Short description</Card.Title>
            <Form.Group>
              <Typography variant='caption'>Enter a short summary of your question</Typography>
              {titleError ?
                <Typography component={'div'} variant='caption' color={'red'}>{titleError}</Typography>
                : ""
              }
              <Form.Control type='text' ref={title} required maxLength={process.env.REACT_APP_QUESTION_DESC_LENGTH}></Form.Control>
            </Form.Group>
          </Card.Body>
        </Card>
        <Card className='shadow-lg bg-white rounded mb-2'>
          <Card.Body>
            <Card.Title>Tags</Card.Title>
            <Form.Group>
              <Typography variant='caption'>Tag with appropriate topic names, a maximum of 3</Typography>
              <Autocomplete
                value={tags}
                //filterOptions={filterOptions}
                onChange={onTagChange}
                getOptionLabel={(option) => { return option.label }}
                renderOption={(props, option) => <li {...props}>{option.title ? option.title : option.label}</li>}
                //freeSolo
                className='w-100'
                size='small'
                options={TagsOptions}
                sx={{ width: 300 }}
                multiple
                renderInput={(params) => <TextField {...params} label="" required={tags.length === 0} />}
              />
            </Form.Group>
          </Card.Body>
        </Card>
        <Card className='shadow-lg bg-white rounded mb-2'>
          <Card.Body>
            <Card.Title>Details</Card.Title>
            <div>
              <Typography variant='caption'>Enter problem statement, what you have tried, errors if any, what are you looking for...</Typography>
              {contentError ?
                <Typography component={'div'} variant='caption' color={'red'}>{contentError}</Typography>
                : ""
              }
              <RichTextEditor setContent={(i) => setContent(i)}></RichTextEditor>
            </div>
          </Card.Body>
        </Card>
        {currentUser ?
          <div className='pb-2'>
            <Button type='Submit' variant='outlined' size='small' className='mb-1 mt-1' style={{ textTransform: 'none' }}>Save</Button>
            {contentError || titleError ?
              <Typography component={'span'} variant='caption' color={'red'} ml={1}>Check above and re-submit</Typography>
              : ""
            }
          </div>
          : <Box p={1}>Login to ask a question</Box>}

      </Form>
    </div>
  )
}



const TagsOptions = tagsList.map((tag) => {
  return { label: tag }
})
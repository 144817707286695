import React, { useEffect, useState } from 'react'
import AddResponse from './AddResponse';
import { useAuth } from '../../../context/AuthContext';
import Response from './Response';
import { InputLabel, Typography } from '@mui/material';

export default function Responses(props) {
    const questionId = props.questionId;
    const { DataAPI } = useAuth();
    const [responses, setResponses] = useState([]);
    const [responseCount, setResponseCount] = useState(0);
    useEffect(() => {
        try {
            if (!responses || responses.length < 1)
                getResponses();
        } catch (e) {
            console.log(e);
        }
    }, [])

    function getResponses() {
        try {
            DataAPI.getResponses(questionId).then((res) => {
                if (res)
                    setResponses(res);
                setResponseCount(res.length);
            })
        } catch (e) {
            console.log(e);
        }
    }

    function getAddedResponse(id) {
        try {
            DataAPI.getResponse(questionId, id).then((res) => {
                if (res) {
                    const responsesNew = [...responses, res];
                    setResponses(responsesNew);
                    setResponseCount(responsesNew.length);
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    function remove(i){
        const newResponses = [...responses];
        newResponses.splice(i,1);
        setResponses(newResponses);
        setResponseCount(newResponses.length);
    }

    return (
        <>
            <div style={{ margin: '8px 0' }}>
                <b>Responses</b>
                <Typography component={'span'} variant='body2'>{" (" + responseCount + ")"}</Typography>
            </div>
            {responses && responses.length > 0 &&
                responses.map((resp,i) => {
                    return <Response key={resp.date} response={resp} onDelete={()=>{remove(i)}}></Response>
                })
            }
            {responseCount === 0 ?
                <Typography component={InputLabel}>None</Typography>
                : ""}
            {responseCount < process.env.REACT_APP_RESPONSES_LIMIT ?
                <AddResponse questionId={questionId} onResponseAdd={getAddedResponse}></AddResponse>
                : <div className='pt-2'></div>
            }
        </>
    )
}

import React, { useEffect } from 'react';
import CommentsLikes from './Comments/CommentsLikes';

export default function Users() {

  useEffect(()=>{
    document.title = 'Users - Rule not found';
  },[])

  return (
    <div>
      Users coming soon...
    </div>
  )
}

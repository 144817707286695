import { Link, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import QuestionsList from './QuestionsList';
import { useAuth } from '../../../context/AuthContext';
import { Label } from '@mui/icons-material';

export default function GetQuestions(props) {
    const category = props.category;
    const createdBefore = props.createdBefore;
    const displayType = props.displayType;
    const startAtQ = useRef({});
    const [qList, setQList] = useState([]);
    const [showLoadmore, setShowLoadMore] = useState(true);
    const { DataAPI, currentUser } = useAuth();

    async function getQuestions() {
        startAtQ.uid = currentUser && currentUser.uid;
        try {
            const qs = await DataAPI.getQuestions(category, createdBefore, startAtQ);
            return qs;
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (qList.length === 0)
            try {
                getQuestions().then((qs) => {
                    setQList(qs);
                })
            } catch (e) {
                console.log(e);
            }
    }, [currentUser])

    async function loadMore(e) {
        e.preventDefault();
        try {
            const moreQs = await getQuestions();
            if (moreQs && Array.isArray(moreQs)) {
                const qs = [...qList, ...moreQs];
                setQList(qs);
            }
            if (!moreQs || !Array.isArray(moreQs) || moreQs.length === 0) {
                setShowLoadMore(false);
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        qList && qList.length > 0 ?
            <div style={{ margin: -8 }}>
                <QuestionsList
                    displayType={displayType}
                    qList={qList}
                />
                {showLoadmore ?
                    <Link
                        p={2}
                        href=''
                        onClick={loadMore}
                    >
                        Load more
                    </Link>
                    : ""}
            </div>
            : category === "myQuestions" ?
                <Typography sx={{ml:1}}>None</Typography>
                : <div className='w-100'>
                    Loading...
                    <Skeleton></Skeleton>
                    <Skeleton animation="wave"></Skeleton>
                    <Skeleton animation="wave"></Skeleton>
                </div>

    )
}
import { setDoc, doc, updateDoc, increment, query, collection, orderBy, getDocs, limit, startAfter, getDoc, where } from 'firebase/firestore';
//import { db } from '../firebase';
import { shorticlesDB as db } from '../firebaseShorticles';

export const shorticleAPI = {
    add(s) {
        return setDoc(doc(db, 'shorticles', "" + s.date), s);
    },
    update(s){
        return setDoc(doc(db, 'shorticles', "" + s.date), s);
    },
    async get(sId) {
        const snapshot = await getDoc(doc(db, 'shorticles', sId));
        const d = snapshot.exists ? snapshot.data() : null;
        if (d && d != null)
            d.id = snapshot.id
        return d;
    },

    async getShorts(queryConfig) {
        let qSnapshot
        const shorts = [];
        if (queryConfig.get === 'prev') {
            if (queryConfig.after > 0)
                qSnapshot = await getDocs(query(
                    collection(db, 'shorticles'),
                    orderBy('date', 'asc'),
                    where('date', '>', queryConfig.after),
                    limit(6)));
            else
                qSnapshot = await getDocs(query(
                    collection(db, 'shorticles'),
                    orderBy('date', 'asc'),
                    limit(6)));
            if (qSnapshot && !qSnapshot.empty)
                qSnapshot.forEach((doc) => {
                    const short = doc.data()
                    short.id = doc.id;
                    shorts.push(short);
                });
                shorts.reverse();

        } else if (queryConfig.get === 'next') {
            if (queryConfig.before > 0)
                qSnapshot = await getDocs(query(
                    collection(db, 'shorticles'),
                    orderBy('date', 'desc'),
                    where('date', '<', queryConfig.before),
                    limit(6)));
            else
                qSnapshot = await getDocs(query(
                    collection(db, 'shorticles'),
                    orderBy('date', 'desc'),
                    limit(6)));
            if (qSnapshot && !qSnapshot.empty)
                qSnapshot.forEach((doc) => {
                    const short = doc.data()
                    short.id = doc.id;
                    shorts.push(short);
                });

        }

        return shorts;
    }
}
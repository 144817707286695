import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import RichTextEditor from '../Utilities/RichTextEditor';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { tags } from '../../StaticData/Tags';

export default function NewShorticle() {
    const [title, setTitle] = useState('');
    const [tag, setTag] = useState('');
    const [detail, setDetail] = useState(null);
    const { currentUser, DataAPI } = useAuth();
    const navigate = useNavigate();

    async function createShorticle(e) {
        try {
            e.preventDefault();
            const shorticle = {
                title: title,
                tag: tag,
                detail: detail,
                userId: currentUser.uid,
                userName: currentUser.displayName,
                likes: 0,
                date: new Date().getTime()
            }

            await DataAPI.addShorticle(shorticle);
            // after successful creation open shorticle, id is same as date
            navigate('/shorticles/'+shorticle.date);
        } catch (e) {
            console.log(e);
        }

    }

    return (
        <Box>
            <Typography sx={{ marginBottom: 2 }} variant='h5'>Create a shorticle</Typography>

            <Box component={'form'}
                onSubmit={createShorticle}
                paddingLeft={1}
            >

                <TextField
                    fullWidth
                    label='Title'
                    type='text'
                    required size='small'
                    sx={{ marginBottom: 2 }}
                    inputProps={{ maxLength: 46 }}
                    onChange={(e) => { setTitle(e.target.value) }}
                ></TextField>

                <FormControl required size='small' sx={{ marginBottom: 2, minWidth: '250px', width: { xs: '100%', sm: 'auto' } }}>
                    <InputLabel id="demo-simple-select-label">Tag</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Tag"
                        value={tag}
                        onChange={(e) => { setTag(e.target.value) }}
                    >
                        {tags.map(e => {
                            return <MenuItem key={e} value={e}>{e}</MenuItem>;
                        })}
                    </Select>
                </FormControl>

                <RichTextEditor setContent={setDetail}></RichTextEditor>

                <Button sx={{ marginY: 2 }} variant='outlined' type='submit'>Save</Button>

            </Box>
        </Box>
    )
}
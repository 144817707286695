import { Box, IconButton, Skeleton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import Shorticle from './Shorticle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function GetShorticles(props) {
  const { DataAPI } = useAuth();
  const [shorticles, setShorticles] = useState([]);
  const [curerntIndex, setCurerntIndex] = useState(-1);
  const [nextBackup, setNextBackup] = useState([]);
  const [prevBackup, setPrevBackup] = useState([]);
  let scrollTop_Initial = 0;
  let scrollRequired = 400;
  let intouch = false;
  const curr = useRef(); // current shorticle in view
  const scrollCotainer = useRef();

  const queryConfig = useRef(
    { after: 0, before: Number.isInteger(props.shorticleId) && props.shorticleId > 0 ? props.shorticleId + 1 : 0, get: 'next' });

  function scrollToNext() {
    try {
      // to fetch more next records in advance
      if (curerntIndex > shorticles.length - 4) {
        if (nextBackup.length > 0) {
          setShorticles([...shorticles, ...nextBackup]);
          setNextBackup([]);
        } else {
          queryConfig.current.before = shorticles[shorticles.length - 1].date;
          queryConfig.current.get = 'next';
          getShorts(true);
        }
      }
      if (curerntIndex + 1 < shorticles.length)
        setCurerntIndex(curerntIndex + 1);
    } catch (e) {
      console.log(e);
    }
  }

  function scrollToPrev() {
    try {
      if (curerntIndex > 0)
        setCurerntIndex(curerntIndex - 1);
      // to fecth more previous records in advance
      if (curerntIndex < 3) {
        if (prevBackup.length > 0) {
          setShorticles([...prevBackup, ...shorticles]);
          setPrevBackup([]);
          setCurerntIndex(curerntIndex + prevBackup.length - 1);
        } else {
          queryConfig.current.after = shorticles[0].date;
          queryConfig.current.get = 'prev';
          getShorts(true);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  function scrollToCurrent() {
    try {
      if (curr && curr.current){
        document.title = shorticles[curerntIndex].title + " #" + shorticles[curerntIndex].tag;
        window.history.replaceState(null,'','/shorticles/'+shorticles[curerntIndex].id);
        curr.current.scrollIntoView({ behaviour: 'instant', block: 'center' });
      }
      if (scrollCotainer && scrollCotainer.current) {
        scrollCotainer.current.focus();
        scrollTop_Initial = scrollCotainer.current.scrollTop;
        scrollRequired = scrollCotainer.current.offsetHeight * 0.95;
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (shorticles.length === 0) {
      getShorts().then(() => {
        scrollToCurrent();
        setCurerntIndex(0);
      })
    }
    // to fetch more previous records if already on last one
    if (curerntIndex === 0 && shorticles.length > 0) {
      queryConfig.current.get = 'prev';
      queryConfig.current.after = shorticles[0].date;
      getShorts();
    }
    scrollToCurrent();
  })

  async function getShorts(backup) {
    try {
      const shorts = await DataAPI.getShorts(queryConfig.current);
      if (Array.isArray(shorts) && shorts.length > 0) {
        if (!backup) {
          if (queryConfig.current.get === 'next') {
            setShorticles([...shorticles, ...shorts]);
          } else if (queryConfig.current.get === 'prev') {
            setShorticles([...shorts, ...shorticles]);
            setCurerntIndex(curerntIndex + shorts.length);
          }
        } else {
          if (queryConfig.current.get === 'next') {
            setNextBackup(shorts);
          } else if (queryConfig.current.get === 'prev') {
            setPrevBackup(shorts);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  let scrollTimer = -1
  function scrollHandler(e) {
    if (scrollTimer > -1 || intouch)
      clearTimeout(scrollTimer);
    scrollTimer = window.setTimeout(() => { scrollTimer = -1; scrollReset() }, 250);
  }

  function scrollReset() {
    if (intouch || scrollTimer > -1) {
      return;
    }
    if (scrollCotainer.current.scrollTop - scrollTop_Initial > scrollRequired) {
      clearTimeout(scrollTimer);
      scrollToNext();
      return
    }
    if (scrollCotainer.current.scrollTop - scrollTop_Initial < -(scrollRequired)) {
      clearTimeout(scrollTimer);
      scrollToPrev();
      return
    }
    scrollToCurrent();
  }

  return (shorticles.length > curerntIndex && curerntIndex >= 0 ?
    <div style={{ height: '100%' }}>
      <Box width={'100%'} display={'flex'} justifyContent={'center'}>
        <IconButton onClick={scrollToPrev} sx={{ position: 'absolute', padding: 0, marginBottom: 0 }}>
          <KeyboardArrowUpIcon />
        </IconButton>
      </Box>

      <Box
        onKeyDown={(e) => {
          if (e.keyCode === 40) {
            e.preventDefault();
            scrollToNext();
          } else {
            if (e.keyCode === 38) {
              e.preventDefault();
              scrollToPrev();
            }
          }
        }}
        tabIndex='0'
        onScroll={scrollHandler}
        onTouchStart={(e) => { intouch = true; }}
        onTouchEnd={(e) => { intouch = false; scrollReset() }}
        ref={scrollCotainer}
        className='hideScroll'
        maxHeight={'732px'}
        minHeight={'80vh'}
        sx={{ height: { xs: `${window.innerHeight}px` }, overflow: 'scroll', padding: 1, margin: 0, outline: 'none', overscrollBehavior: 'none' }}
      >

        {curerntIndex > 0 ?
          <Box key={shorticles[curerntIndex - 1].id} mb={1} height={'100%'}><Shorticle shorticle={shorticles[curerntIndex - 1]} ></Shorticle></Box>
          : ""}

        <Box key={shorticles[curerntIndex].id} ref={curr} mb={1} height={'100%'}><Shorticle shorticle={shorticles[curerntIndex]} ></Shorticle></Box>

        {shorticles.length > curerntIndex + 1 ?
          <Box key={shorticles[curerntIndex + 1].id} mt={1} height={'100%'}><Shorticle shorticle={shorticles[curerntIndex + 1]} ></Shorticle></Box>
          : ""}

      </Box>

      <Box width={'100%'} display={'flex'} justifyContent={'center'}>
        <IconButton onClick={scrollToNext} sx={{ position: 'absolute', padding: 0, mt: -3 }}>
          <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
        </IconButton>
      </Box>

    </div>

    : <div className='w-100' style={{margin:'8px'}}>
      Loading...
      <Skeleton></Skeleton>
      <Skeleton animation="wave"></Skeleton>
      <Skeleton animation="wave"></Skeleton>
    </div>
  )
}

const emptyFirst = <Box mb={2} height={'100%'}>
  <Shorticle
    shorticle={{ title: 'No more shorticles', tag: 'none', detail: { blocks: [{ key: '4cqfa', text: 'No more data, scroll down' }] } }}
  ></Shorticle>
</Box>

const emptyLast = <Box mb={2} height={'100%'}>
  <Shorticle
    shorticle={{ title: 'No more shorticles', tag: 'none', detail: { blocks: [{ key: '4cqfa', text: 'No more data, scroll up' }] } }}
  ></Shorticle>
</Box>
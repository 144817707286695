import React from 'react'
import QuestionListItem from './QuestionListItem';

export default function QuestionsList(props) {
    const qList = Array.isArray(props.qList) ? props.qList : [];
    const displayType = (props.displayType && props.displayType !== "" ? props.displayType : 'list');

    return (
        <div
            className={displayType === "grid" ? 'd-flex flex-row flex-wrap' : 'd-flex flex-column'}
        >
            {qList.map((q) => {
                return <QuestionListItem
                    key={q.id}
                    displayType={displayType}
                    question={q}
                />
            })}
        </div>
    )
}

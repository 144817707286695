// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCh9fZ2uLCmuNQuHX2mw-ciBA9XOQsLjsY",
    authDomain: "rnfresponses-d0995.firebaseapp.com",
    projectId: "rnfresponses-d0995",
    storageBucket: "rnfresponses-d0995.appspot.com",
    messagingSenderId: "463742892165",
    appId: "1:463742892165:web:2dacbfedfa60ce04c651ec",
    measurementId: "G-M3PJZ6FT5Y"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig,"responses");
export const responsesDB = getFirestore(app);
import { setDoc, doc, updateDoc, increment, query, collection, orderBy, getDocs, limit, startAfter, where, deleteDoc } from 'firebase/firestore';
//import { db } from '../firebase';
import { lighterQsDB as db } from '../firebaseLighterQs';

export const lighterQuestionAPI = {

    // new question
    add(question, id) {
        const lq = {
            date: question.date,
            title: question.title,
            tags: question.tags,
            userId: question.userId,
            userName: question.userName,
            likes: question.likes,
            responses: question.responses,
            totalLikes: 0,
            updateDate: new Date().getTime()
        }
        return setDoc(doc(db, 'lighterqs', id), lq);
    },
    // delete question
    delete(qid){
        return deleteDoc(doc(db,'lighterqs', qid));
    },
    // new like
    like(id) {
        return updateDoc(doc(db, 'lighterqs', id), { likes: increment(1), totalLikes: increment(1), updateDate: new Date().getTime() });
    },
    // an unlike
    unlike(id) {
        return updateDoc(doc(db, 'lighterqs', id), { likes: increment(-1), totalLikes: increment(-1) });
    },
    // new response
    newResponse(id) {
        return updateDoc(doc(db, 'lighterqs', id), { responses: increment(1), updateDate: new Date().getTime() });
    },
    // new like on response
    likeResponse(id) {
        return updateDoc(doc(db, 'lighterqs', id), { totalLikes: increment(1), updateDate: new Date().getTime() });
    },
    // an unlike on response
    unLikeResponse(id) {
        return updateDoc(doc(db, 'lighterqs', id), { totalLikes: increment(-1) });
    },
    // update title
    updateTitle(id,title){
        return updateDoc(doc(db,'lighterqs',id),{title:title});
    }
,
    // get recent questions
    async getRecentQuestions(createdBefore,startAtQ) {
        let qSnapshot = null;
        const startAtQVal = startAtQ && startAtQ.val ? startAtQ.val : null;
        if(startAtQVal){
            qSnapshot = await getDocs(query(
                collection(db, 'lighterqs'),
                orderBy('date','desc'),
                startAfter(startAtQVal),limit(6)));
        }else
        if (createdBefore && Number.isInteger(createdBefore)) {
            qSnapshot = await getDocs(query(
                collection(db, 'lighterqs'),
                orderBy('date','desc'),
                startAfter(createdBefore),limit(6)));
        }else{
            qSnapshot = await getDocs(query(
                collection(db, 'lighterqs'),
                orderBy('date','desc'), limit(6)));
        }
        const qs = [];
        if(!qSnapshot.empty){
            startAtQ.val = qSnapshot.docs[qSnapshot.docs.length-1];
        }
        qSnapshot.forEach((doc) => {
            const q = doc.data()
            q.id = doc.id;
            qs.push(q);
        })
        return qs;
    },

    // get top questions
    async getTopQuestions(createdBefore,startAtQ) {
        let qSnapshot = null;
        const startAtQVal = startAtQ && startAtQ.val ? startAtQ.val : null;
        if(startAtQVal){
            qSnapshot = await getDocs(query(
                collection(db, 'lighterqs'),
                orderBy('totalLikes','desc'),
                startAfter(startAtQVal),limit(6)));
        }else{
            qSnapshot = await getDocs(query(
                collection(db, 'lighterqs'),
                orderBy('totalLikes','desc'), limit(6)));
        }
        const qs = [];
        if(!qSnapshot.empty){
            startAtQ.val = qSnapshot.docs[qSnapshot.docs.length-1];
        }
        qSnapshot.forEach((doc) => {
            const q = doc.data()
            q.id = doc.id;
            qs.push(q);
        })
        return qs;
    },

    // get questions
    async getQuestions(category,createdBefore,startAtQ){
        if(category==="recent"){
            return this.getRecentQuestions(createdBefore,startAtQ);
        }
        if(category==='top'){
            return this.getTopQuestions(createdBefore,startAtQ);
        }
        if(category==='myQuestions'){
            return this.getMyQuestions(createdBefore,startAtQ);
        }
    },

    // get my questions
    async getMyQuestions(createdBefore,startAtQ) {
        let qSnapshot = null;
        const startAtQVal = startAtQ && startAtQ.val ? startAtQ.val : null;
        if(startAtQVal){
            qSnapshot = await getDocs(query(
                collection(db, 'lighterqs'),
                where('userId','==',startAtQ.uid+""),
                orderBy('date','desc'),
                startAfter(startAtQVal),limit(6)));
        }else
        if (createdBefore && Number.isInteger(createdBefore)) {
            qSnapshot = await getDocs(query(
                collection(db, 'lighterqs'),
                where('userId','==',startAtQ.uid+""),
                orderBy('date','desc'),
                startAfter(createdBefore),limit(6)));
        }else{
            qSnapshot = await getDocs(query(
                collection(db, 'lighterqs'),
                where('userId','==',startAtQ.uid+""),
                orderBy('date','desc'), limit(6)));
        }
        const qs = [];
        if(!qSnapshot.empty){
            startAtQ.val = qSnapshot.docs[qSnapshot.docs.length-1];
        }
        qSnapshot.forEach((doc) => {
            const q = doc.data()
            q.id = doc.id;
            qs.push(q);
        })
        return qs;
    }

}
import React, { useRef, useState } from 'react'
import RichTextEditor from '../../Utilities/RichTextEditor';
import { useAuth } from '../../../context/AuthContext';
import { Button, Typography } from '@mui/material';

export default function AddResponse(props) {
    const questionId = props.questionId;
    const onResponseAdd = props.onResponseAdd;
    let response = useRef(null);
    function setResponse(res){
        response = res;
    }
    const { currentUser, DataAPI } = useAuth();
    const [responseError, setResponseError] = useState();
    const [RTEKey, setRTEKey] = useState("RTEKeyResponse");

    async function addResponse() {
        try {
            if (!isResponseValid()) {
                return;
            }
            const responseObj = {
                userId: currentUser.uid,
                userName: currentUser.displayName,
                date: (new Date()).getTime(),
                response: response,
                questionId: questionId,
                likes: 0
            }
            const resId = await DataAPI.addResponse(questionId, responseObj)
            onResponseAdd(resId);
            setRTEKey(RTEKey + 'A');
        } catch (e) {
            console.log(e);
        }
    }

    function isResponseValid() {
        let retValue = true;
        setResponseError(null);
        if (!response || !response.blocks || (response.blocks.length === 1 && (response.blocks[0].text === "") || response.blocks[0].text == null)) {
            setResponseError('Enter response');
            retValue = false;
        } else
            if (JSON.stringify(response).length > process.env.REACT_APP_RESPONSE_LENGTH) {
                setResponseError('Exceeds max length allowed, reduce content to ' + Math.floor((process.env.REACT_APP_RESPONSE_LENGTH * 100 / JSON.stringify(response).length)) + '% of current');
                setResponseError(JSON.stringify(response).length + ' - length')
                retValue = false;
            }
        return retValue;
    }

    return (
        currentUser ?
            <div>
                <div style={{ marginBottom: '4px' }}>Add response</div>
                {responseError ?
                    <Typography component={'div'} variant='caption' color={'red'}>{responseError}</Typography>
                    : ""
                }
                <div>
                    <RichTextEditor key={RTEKey} setContent={setResponse}></RichTextEditor>
                </div>
                <Button variant='outlined' style={{ textTransform: 'none' }} className='mt-2' onClick={addResponse} size='small'>Respond</Button>
                {responseError ?
                    <Typography component={'span'} variant='caption' color={'red'} ml={1}>Check above and re-submit</Typography>
                    : ""
                }
            </div>
            : <div>Login to add a response  </div>
    )
}
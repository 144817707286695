import React from 'react';
import { createRoot } from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from './context/AuthContext';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import App from './components/App';
import Shorticles from './components/Shorticles';
import AppWithoutHeader from './components/AppWithoutHeader';


const root = createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <AuthProvider>
//       <Router>
//         <App />
//       </Router>
//     </AuthProvider>
//   </React.StrictMode>
// );

root.render(
  <React.StrictMode>
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/shorticles/*" Component={AppWithoutHeader}></Route>
          <Route path="/*" Component={App}></Route>
        </Routes>
      </Router>
    </AuthProvider>
  </React.StrictMode>
);
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, IconButton, TextField } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import RichTextEditor from '../Utilities/RichTextEditor';

export default function ShorticlEdit(props) {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = useState(props.shorticle.title);
  const [detail, setDetail] = useState(props.shorticle.detail);
  const { DataAPI } = useAuth();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSave = async () => {
    const article = { ...props.shorticle };
    article.detail = detail;
    article.title = title;
    try {
      await DataAPI.updateShorticle(article);
    } catch (e) {
      console.log(e);
    }
    props.onUpdate(article);
    setOpen(false);
  };

  function handleCancel() {
    setDetail(props.shorticle.detail);
    setTitle(props.shorticle.title);
    setOpen(false);
  }

  return (
    <Box sx={{ display: 'inline', ml: 'auto' }}>
      <IconButton onClick={handleClickOpen} size='small' sx={{ py: 0, ml: 'auto' }}><EditOutlinedIcon fontSize='inherit' /></IconButton>
      {open &&
        <Dialog
          sx={{ '& .MuiPaper-root': { minWidth: { xs: '100%', sm: '500px' } } }}
          open={open}
          onClose={handleCancel}>
          <DialogTitle>Update shorticle</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Title"
              type="text"
              fullWidth
              variant="standard"
              value={title}
              onChange={(e) => { setTitle(e.target.value) }}
            />
          </DialogContent>
          <DialogContent>
            <DialogContentText>
              Shorticle content
            </DialogContentText>
            <RichTextEditor rawContent={props.shorticle.detail} setContent={setDetail} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSave}>Save</Button>
          </DialogActions>
        </Dialog>
      }
    </Box>
  );
}
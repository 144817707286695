import { Card, CardActions, CardContent, CardHeader, InputLabel, Link, Typography } from '@mui/material';
import React from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ReplyIcon from '@mui/icons-material/Reply';
import { useNavigate } from 'react-router-dom';
import TimeAgo from '../../Utilities/TimeAgo';

export default function QuestionListItem(props) {
    const question = props.question;
    const displayType = typeof props.displayType === "string" ? props.displayType : "list";
    const navigate = useNavigate();

    function navigateToQuestion(e) {
        e.preventDefault();
        navigate("/question/" + question.id);
    }

    return (
        displayType === "grid" ?
            <Card sx={{ minWidth: 250, maxWidth: 380, marginY: 0.5, marginX:1, flex: 1, display: 'flex', flexDirection: 'column' }} raised>
                <CardHeader
                    title={
                        <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                            <div className='d-flex flex-wrap align-items-center'>
                                <Typography variant='caption' component={'span'} mr={1}><TimeAgo milliSeconds={question.date} /> </Typography>
                            </div>
                            <div className='d-flex align-items-center'>
                                <Typography variant='caption' component={'span'} mr={1} whiteSpace={'nowrap'}><ThumbUpIcon style={{ marginRight: 1 }} fontSize='inherit' htmlColor='gray' />{question.likes}</Typography>
                                <Typography variant='caption' component={'span'} whiteSpace={'nowrap'}><ReplyIcon fontSize='inherit' htmlColor='gray' />{question.responses}</Typography>
                            </div>
                        </div>
                    }
                    sx={{paddingY:1}}
                />
                <CardContent sx={{paddingY:0}}>
                    <Link
                        underline='none'
                        onClick={navigateToQuestion}
                        href='#'
                    >
                        {question.title}
                    </Link>
                </CardContent>
                <CardActions sx={{ marginTop: 'auto', pr:2,pl:2,flexWrap:'wrap'}}>
                    {question.tags && Array.isArray(question.tags) ?
                        question.tags.map((tag) => {
                            return <Typography sx={{whiteSpace:'nowrap',mr:1,ml:'0 !important'}} key={tag} variant='caption'>{"# " + tag}</Typography>;
                        })
                        : ""

                    }
                </CardActions>
                
            </Card>

            : <div className='d-flex align-items-center' style={{ padding: 8 }}>
                <div style={{ flex: 1 }}>
                    <Link
                        underline='none'
                        onClick={navigateToQuestion}
                        href='#'
                        mr={1}
                    >
                        <Typography variant='body2' component={'span'}>{question.title}</Typography>
                    </Link>
                    <Typography display={'inline'} component={InputLabel} variant='caption'>
                        {new Date(question.date).toLocaleDateString()}
                    </Typography>
                </div>
                <div style={{ marginRight: 'auto' }}>
                    <Typography style={{ whiteSpace: 'nowrap' }} variant='body2'>
                        <ThumbUpIcon style={{ marginRight: 1 }} fontSize='inherit' htmlColor='gray' />
                        {question.likes}
                    </Typography>
                </div>
            </div>
    )
}
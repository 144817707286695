import React, { useRef, useState } from 'react';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

export default function ForgotPassword(props) {
  const replaceWith = props.replaceWith;
  const emailRef = useRef();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLaoding] = useState(false);
  const { resetPassword } = useAuth();
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLaoding(true);
      setMessage("");
      setError("");
      await resetPassword(emailRef.current.value);
      setMessage("Password reset email sent");
    } catch (error) {
      setError(error.message.replace('Firebase: ',''));
    }
    setLaoding(false);
  };
  return (
    <div className='d-flex align-items-center justify-content-center w-100'>
      <div className='w-100' style={{ maxWidth: "400px" }}>
        <Card>
          <Card.Body>
            <div className='w-100 text-center'>
              <h2>Forgot Password</h2>
              {message && <Alert variant='success'>{message}</Alert>}
              {error && <Alert variant='danger'>{error}</Alert>}
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control type='email' ref={emailRef} required></Form.Control>
              </Form.Group>
              <Button disabled={loading} type='submit' className='w-100 mt-4'>Reset Password</Button>
            </Form>
          </Card.Body>
          <div className='w-100 text-center mb-2'>
            <Link onClick={()=>{replaceWith('login')}}>Log In</Link>
          </div>
        </Card>
        <div className='w-100 text-center mt-2'>
          Need an account ? <Link onClick={()=>{replaceWith('signup')}}>Sign Up</Link>
        </div>
      </div>
    </div>
  )
}

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCLejPZ-oWxaHFI6D5T8-9hEMaH5cF3O8M",
    authDomain: "rnfshorticles-61b63.firebaseapp.com",
    projectId: "rnfshorticles-61b63",
    storageBucket: "rnfshorticles-61b63.appspot.com",
    messagingSenderId: "621291180051",
    appId: "1:621291180051:web:e78d513c09b8a8f9226fe6",
    measurementId: "G-7NJVWC4TL9"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig,"shorticles");
export const shorticlesDB = getFirestore(app);
import React, { useState } from 'react';
import { Alert, Card } from 'react-bootstrap';
import { useAuth } from '../../context/AuthContext';

export default function UserProfile(props) {
    const replaceWith = props.replaceWith;
    const { currentUser, logout } = useAuth();
    const [error, setError] = useState("");

    return (
        <div className='d-flex align-items-center justify-content-center w-100'>
            <div className='w-100' style={{ maxWidth: "400px" }}>
                <Card>
                    <Card.Body>
                        <h2 className='text-center mb2'>Profile</h2>
                        {error && <Alert variant='danger'>{error}</Alert>}
                        <strong>Name: </strong>{currentUser && currentUser.displayName}
                        <div>
                            <strong>Email: </strong>{currentUser && currentUser.email}
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}

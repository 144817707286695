import { Box, Dialog, IconButton, Link, Toolbar, Typography, Badge, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth } from '../context/AuthContext';
import UserAccount from './Authenticate/UserAccount';
import { useNavigate } from 'react-router-dom';
import AccountMenu from './Authenticate/AccountMenu';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default function Header() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { currentUser,adminUser } = useAuth();
  const navigate = useNavigate();

  function openDialog(e) {
    e.preventDefault();
    setDialogOpen(true);
  }

  function handleClose() {
    setDialogOpen(false);
  }

  const dialog = (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      fullScreen
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
        </Typography>
        <IconButton
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <UserAccount displayComponent='login'></UserAccount>
    </Dialog>)

  function newQuestion() {
    navigate("/newQuestion");
  }

  function newShorticle(){
    navigate("/newShorticle");
  }

  return (
    <Box flexGrow={1} display={'flex'} alignItems={'center'}>
      <Typography flex={1}><img height={'60px'} src='/rnf.svg'></img> </Typography>

      {currentUser && adminUser ?
        <Tooltip title='New Shorticle'>
          <IconButton
            color="inherit"
            onClick={newShorticle}
            sx={{ marginRight: 1 }}
          >
            <Badge
              badgeContent={'+'}
              color='primary'
              sx={{ '& .MuiBadge-badge': { width: 16, height: 16, borderRadius: '50%' } }}
            >
              <FeedOutlinedIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        : ""}

      {currentUser ?
        <Tooltip title='New Question'>
          <IconButton
            color="inherit"
            onClick={newQuestion}
            sx={{ marginRight: 1 }}
          >
            <Badge
              badgeContent={'+'}
              color='primary'
              sx={{ '& .MuiBadge-badge': { width: 16, height: 16, borderRadius: '50%' } }}
            >
              <HelpOutlineIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        : ""}

      {currentUser ?
        <AccountMenu></AccountMenu>
        : <Link href='#' onClick={openDialog}>login</Link>
      }
      {dialog}
    </Box>
  )
}
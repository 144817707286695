import React, { useEffect, useState } from 'react'
import GetQuestions from './Question/QList/GetQuestions'
import { Box, IconButton, Typography } from '@mui/material'
import GridViewIcon from '@mui/icons-material/GridView';
import ReorderIcon from '@mui/icons-material/Reorder';

export default function Questions() {
  const [view, setView] = useState('grid');
  const [topView, setTopView] = useState('grid');

  useEffect(()=>{
    document.title = 'Questions - Rule not found';
  },[])

  return (
    <div className='mw-100 w-100'>
      <Box display={'flex'} mb={1} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant='h6'>Recent questions</Typography>
        {view === 'grid' ?
          <IconButton size='small' onClick={() => { setView('list') }}><ReorderIcon /></IconButton>
          : <IconButton size='small' onClick={() => { setView('grid') }}><GridViewIcon /></IconButton>}
      </Box>
      <GetQuestions category={'recent'} displayType={view}></GetQuestions>

      <Box display={'flex'} mb={1} mt={3} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant='h6'>Top questions</Typography>
        {topView === 'grid' ?
          <IconButton size='small' onClick={() => { setTopView('list') }}><ReorderIcon /></IconButton>
          : <IconButton size='small' onClick={() => { setTopView('grid') }}><GridViewIcon /></IconButton>}
      </Box>
      <GetQuestions category={'top'} displayType={topView}></GetQuestions>

      <div style={{ minHeight: 5 }}></div>
    </div>

  )
}
import { doc, getDoc, setDoc, deleteDoc, updateDoc, increment, serverTimestamp, arrayUnion, query, collection, limit, getDocs, arrayRemove } from "firebase/firestore";
import { db } from '../firebase';
import { lighterQuestionAPI } from "./lighterQuestionAPI";
import { shorticleAPI } from "./shorticleAPI";
import { commentsAPI } from "./commentsAPI";
import { likesAPI } from "./LikesAPI";
import { responseAPI } from "./responseAPI";


export const AppData = {

    getDataAPI() {

        return {
            sxQuestion: lighterQuestionAPI,

            responses: responseAPI,

            shorticles: shorticleAPI,

            comments: commentsAPI,

            likesAPI: likesAPI,

            // Add user to data store
            async addUser(userId, name, email) {
                await setDoc(doc(db, "users", userId), { userId, name, email });
            },

            // Add question to data store
            async addNewQuestion(question) {
                const questionId = "Q" + question.date + "_" + (Math.random() * 10).toFixed(0) + (Math.random() * 10).toFixed(0);
                question.fbDate = serverTimestamp();
                await setDoc(doc(db, "questions", questionId), question);
                await this.sxQuestion.add(question, questionId);
                return questionId;
            },

            // delete question
            async deleteQ(qid){
                await this.sxQuestion.delete(qid);
                await this.responses.deleteAllResponses(qid);
                await deleteDoc(doc(db,'questions',qid));
            },

            // update question title
            async updateQuestionTitle(id,title){
                await updateDoc(doc(db,'questions',id),{title:title});
                await this.sxQuestion.updateTitle(id,title);
                return true;
            },

            async updateQuestionDetail(id,detail){
                await updateDoc(doc(db,'questions',id),{detail:detail});
                return true;
            },

            // Fetch question from data store
            async getQuestion(id) {
                const qSnapshot = await getDoc(doc(db, "questions", id));
                if (qSnapshot.exists()) {
                    const question = qSnapshot.data();
                    question.id = qSnapshot.id;
                    return question;
                }
            },

            // Like question
            async likeQuestion(id, userId) {
                await setDoc(doc(db, "questions", id, "likes", userId), { uId: userId, fbDate: serverTimestamp() });
                await updateDoc(doc(db, "questions", id), { likes: increment(1) });
                await this.sxQuestion.like(id);
            },

            // unlike question
            async unLikeQuestion(id, userId) {
                await deleteDoc(doc(db, "questions", id, "likes", userId));
                await updateDoc(doc(db, "questions", id), { likes: increment(-1) });
                await this.sxQuestion.unlike(id);
            },

            // check if a question is liked by user
            async IsQuestionLikedByUser(qid, uid) {
                const docSnapshot = await getDoc(doc(db, "questions", qid, "likes", uid));
                return docSnapshot.exists() ? true : false;
            },

            // Add comment to question
            async addComment_Question(qid, comment) {
                await updateDoc(doc(db, "questions", qid), { comments: arrayUnion(comment) })
            },

            // delete question comment
            async deleteQComment(qid,comment){
                await updateDoc(doc(db,"questions",qid),{comments: arrayRemove(comment)});
            },

            // get question comments
            async getComments_Question(qid) {
                const getQ = await this.getQuestion(qid);
                return getQ && getQ.comments;
            },

            // add response to question
            async addResponse(qid, responseObj) {
                await this.responses.addResponse(qid,responseObj);
                await this.sxQuestion.newResponse(qid);
                return responseObj.date;
            },

            // update response
            async updateResponse(qid,id,response){
                return this.responses.updateResponse(qid,id,response);
            },

            // get responses to question
            async getResponses(qid) {
                return this.responses.getResponses(qid);
            },

            // get a response
            async getResponse(qid, rid) {
                return this.responses.getResponse(qid, rid);
            },

            // delete response
            async deleteResponse(qid,rid){
                return this.responses.delete(qid,rid);
            },

            // check if response is liked by user
            async IsResponseLikedByUser(qid, rid, uid) {
                return this.responses.IsResponseLikedByUser(qid, rid, uid);
            },

            // like response
            async likeResponse(qid, rid, uid) {
                this.responses.likeResponse(qid, rid, uid)
                await this.sxQuestion.likeResponse(qid);
            },

            // unlike response
            async unLikeResponse(qid, rid, uid) {
                this.responses.unLikeResponse(qid, rid, uid);
                await this.sxQuestion.unLikeResponse(qid);
            },

            // get response comments
            async getComments_Response(qid, rid) {
                return this.responses.getComments_Response(qid, rid);
            },

            // add comment to response
            async addComment_Response(qid, rid, comment) {
                return this.responses.addComment_Response(qid, rid, comment);
            },

            // delete response comment
            async deleteRComment(qid,rid,comment){
                return this.responses.deleteRComment(qid,rid,comment);
            },

            // get questions
            async getQuestions(category, createdBefore, startAtQ) {
                return this.sxQuestion.getQuestions(category, createdBefore, startAtQ);
            },

            // add shorticle
            addShorticle(s) {
                return this.shorticles.add(s);
            },
            
            // update shorticle
            updateShorticle(shorticle){
                return this.shorticles.update(shorticle);
            },

            // get shorticle
            getShorticle(sId) {
                return this.shorticles.get(sId);
            },

            // get shorts list
            getShorts(queryConfig) {
                return this.shorticles.getShorts(queryConfig);
            },

            // add comment
            addComment(commentFor, commentObj) {
                return this.comments.add(commentFor, commentObj);
            },

            // get comments
            getComments(commentsFor, startAfter) {
                return this.comments.getComments(commentsFor, startAfter);
            },

            // delete comment
            deleteComment(commentFor, commentId) {
                return this.comments.delete(commentFor, commentId);
            },

            // like
            like(likeFor, likeBy) {
                return this.likesAPI.like(likeFor, likeBy);
            },

            // unlike
            unlike(likeFor, likeBy) {
                return this.likesAPI.unlike(likeFor, likeBy);
            },

            // isLikedByUser
            isLikedByUser(likeFor, likeBy) {
                return this.likesAPI.isLikedByUser(likeFor, likeBy);
            },

            // likes count
            getlikesCount(likesFor) {
                return this.likesAPI.getlikesCount(likesFor);
            },

            // is admin user
            async isAdminUser(uid) {
                const snapshot = await getDoc(doc(db, 'admins', ""+uid));
                if (snapshot.exists())
                    return true;
                return false;
            }
        }

    }
}
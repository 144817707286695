import React, { useEffect } from 'react'

import { Typography } from '@mui/material'

export default function Home() {

  useEffect(()=>{
    document.title = 'Home - Rule not found';
  },[])

  return (
    <div className='mw-100 w-100'>
      Thanks for visiting rulenotfound.com.
      <br/>
      <br/>
      We developed this as a forum to discuss any questions on Pega and related technologies.
      <br/>
      <br/>
      You can go through shorticles (short articles) to learn and brushup your concepts of Pega.
      <br/>
      <br/>
      <Typography variant='caption'>
        For any feedback, suggestions on topics, site improvements or issues please write to us at <a href="mailto:feedback@rulenotfound.com">feedback@rulenotfound.com</a>.
      </Typography>
    </div>

  )
}
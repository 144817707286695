import { Box, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GetQuestions from './Question/QList/GetQuestions'
import GridViewIcon from '@mui/icons-material/GridView';
import ReorderIcon from '@mui/icons-material/Reorder';

export default function MyQuestions() {
    const [view, setView] = useState('grid');

    useEffect(()=>{
        document.title = 'My questions - Rule not found';
      },[])

    return (
        <div className='mw-100 w-100'>
            <Box display={'flex'} mb={1} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant='h6'>My questions</Typography>
                {view === 'grid'?
                <IconButton size='small' onClick={()=>{setView('list')}}><ReorderIcon /></IconButton>
                :<IconButton size='small' onClick={()=>{setView('grid')}}><GridViewIcon/></IconButton>}
            </Box>

            <GetQuestions category={'myQuestions'} displayType={view}></GetQuestions>
        </div>
    )
}

import { collection, deleteDoc, doc, getDocs, limit, orderBy, query, setDoc, startAfter, where } from "firebase/firestore"
//import { db } from '../firebase';
import { commentsDB as db } from "../firebaseComments";

export const commentsAPI = {
    async add(commentFor, commentObj) {
        await setDoc(doc(db, 'comments', commentFor, 'comments', ""+commentObj.date), commentObj);
    },

    async delete(commentFor,commentId){
        await deleteDoc(doc(db,'comments',commentFor,'comments',""+commentId));
    },

    async getComments(commentsFor,startAfter) {
        let qSnapshot;
        if(startAfter > 0)
        qSnapshot = await getDocs(query(
            collection(db, 'comments',commentsFor,'comments'),
            where('date','<',startAfter),
            orderBy('date', 'desc'),
            limit(6)));
        else
        qSnapshot = await getDocs(query(
            collection(db, 'comments',commentsFor,'comments'),
            orderBy('date', 'desc'),
            limit(6)));
        const comments = [];
        if (qSnapshot && !qSnapshot.empty)
            qSnapshot.forEach((doc) => {
                const comment = doc.data()
                comment.id = doc.id;
                comments.push(comment);
            });
        return comments;
    }
}
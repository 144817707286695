import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, CardHeader, Skeleton, Typography } from '@mui/material';
import RichTextEditorReadOnly from '../Utilities/RichTextEditorReadOnly';
import CommentsLikes from '../Comments/CommentsLikes';
import { useAuth } from '../../context/AuthContext';
import ShorticlEdit from './ShorticleEdit';

export default function Shorticle(props) {
  const [shorticle, setShorticle] = useState(props.shorticle);
  const {currentUser,adminUser}=useAuth();

  useEffect(() => {
    setShorticle(props.shorticle)
  }, [props.shorticle])

  return (shorticle ?
    <Box key={shorticle.id} sx={{ display: 'flex', maxHeight: '700px', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <Card
        raised
        sx={{ p: 0, maxWidth: '600px', maxHeight: '700px', borderRadius: 2, width: { xs: '100%' }, height: { xs: '100%' }, display: 'flex', flexDirection: 'column' }}
      >

        <CardHeader
          title={
            <Box>
              {shorticle.title}
              {currentUser && (currentUser.uid === shorticle.userId || adminUser) &&
            <ShorticlEdit shorticle={shorticle} onUpdate={setShorticle}/>}
            </Box>
          }
          sx={{ borderBottom: '1px solid black' }}></CardHeader>

        <CardContent sx={{ paddingY: 0 }}>
          <Typography component={'p'} sx={{ marginTop: '-12px', bgcolor: 'yellow', width: 'fit-content', paddingX: 1, borderRadius: '50%' }}>
            {'# ' + shorticle.tag}
          </Typography>
        </CardContent>

        <CardContent sx={{ overflow: 'scroll' }}>
          <RichTextEditorReadOnly rawContent={shorticle.detail}></RichTextEditorReadOnly>
        </CardContent>

      </Card>
      {
        <Box sx={{ marginLeft: '-64px', pr: 1, marginTop: 'auto', marginBottom: '16px' }}><CommentsLikes objid={shorticle.id} title={shorticle.title} shareurl={'/shorticles/' + shorticle.id} /></Box>
      }

    </Box>

    : <Box className='w-100'>
      Loading...
      <Skeleton></Skeleton>
      <Skeleton animation="wave"></Skeleton>
      <Skeleton animation="wave"></Skeleton>
    </Box>
  )
}
import { collection, deleteDoc, doc, getCountFromServer, getDoc, setDoc } from 'firebase/firestore';
//import { db } from '../firebase';
import { likesDB as db } from '../firebaseLikes';

export const likesAPI = {
    like(likeFor, likeBy) {
        return setDoc(doc(db, 'likes', likeFor, 'likes', "" + likeBy), {});
    },
    unlike(likeFor, likeBy) {
        return deleteDoc(doc(db, 'likes', likeFor, 'likes', "" + likeBy));
    },
    async isLikedByUser(likeFor, likeBy) {
        const snapshot = await getDoc(doc(db, 'likes', likeFor, 'likes', "" + likeBy));
        if (snapshot.exists())
            return true;
        return false;
    },

    async getlikesCount(likesFor){
        const snapshot = await getCountFromServer(collection(db,'likes',likesFor,'likes'));
        return snapshot.data().count;
    }
}
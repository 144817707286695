// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDCQLqHgbzLFDn60pzXSzNssfahiS9AcW8",
    authDomain: "rnflighterqs-68f5b.firebaseapp.com",
    projectId: "rnflighterqs-68f5b",
    storageBucket: "rnflighterqs-68f5b.appspot.com",
    messagingSenderId: "773169880732",
    appId: "1:773169880732:web:bbec4c30b0a44d7b1a5759",
    measurementId: "G-DTECYVHXFN"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig,"lighterQs");
export const lighterQsDB = getFirestore(app);
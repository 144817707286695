import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, IconButton } from '@mui/material';
import { useAuth } from '../../../context/AuthContext';
import RichTextEditor from '../../Utilities/RichTextEditor';

export default function ResponseEdit(props) {
  const [open, setOpen] = React.useState(false);
  const [response, setDetail] = useState(props.response.response);
  const { DataAPI } = useAuth();

  const handleClickOpen = () => {
    setOpen(true);
    setDetail(props.response.response);
  };

  const handleSave = async () => {
    const newR = { ...props.response };
    newR.response = response;
    try {
      await DataAPI.updateResponse(props.response.questionId,props.response.id, response);
    } catch (e) {
      console.log(e);
    }
    props.onUpdate(newR);
    setOpen(false);
  };

  function handleCancel() {
    setDetail(props.response.response);
    setOpen(false);
  }

  return (
    <Box sx={{ display: 'inline', ml: 'auto' }}>
      <IconButton onClick={handleClickOpen} size='small' sx={{ py: 0, ml: 'auto' }}><EditOutlinedIcon fontSize='inherit' /></IconButton>
      {open &&
        <Dialog
          sx={{ '& .MuiPaper-root': { minWidth: { xs: '100%', sm: '500px' } } }}
          open={open}
          onClose={handleCancel}>
          <DialogTitle>Update response</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Add a response to the question
            </DialogContentText>
            <RichTextEditor rawContent={props.response.response} setContent={setDetail} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSave}>Save</Button>
          </DialogActions>
        </Dialog>
      }
    </Box>
  );
}
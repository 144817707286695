import { Box, Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import TimeAgo from '../../Utilities/TimeAgo';
import ResponseLikeUnlike from './ResponseLikeUnlike';
import { useAuth } from '../../../context/AuthContext';
import RichTextEditorReadOnly from '../../Utilities/RichTextEditorReadOnly';
import Comments from '../Comments';
import ShareIcon from '@mui/icons-material/Share';
import ResponseEdit from './ResponseEdit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export default function Response(props) {
    const [response, setResponse] = useState(props.response);
    const { DataAPI, currentUser, adminUser } = useAuth();

    function getResponse() {
        try {
            DataAPI.getResponse(response.questionId, response.id).then((res) => {
                setResponse(res);
            })
        } catch (e) {
            console.log(e);
        }
    }

    function share(e) {
        try {
            const shareData = {
                url: '?' + response.id,
                text: 'check out this question',
                title: 'check out this question'
            }
            if (navigator.canShare(shareData))
                navigator.share(shareData);
        } catch (e) {
            console.log(e);
        }
    }

    async function deleteRes() {
        try {
            if (!window.confirm('are you sure, you want to delete the response?'))
                return
            await DataAPI.deleteResponse(response.questionId, response.id);
            props.onDelete();
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Card raised className='mb-2'>
            <CardHeader
                title={
                    <div className='d-flex align-items-center' style={{ justifyContent: 'space-between' }}>
                        <div className='d-flex flex-wrap'>
                            <Typography component={'div'} variant='caption' mr={2}><AccessTimeIcon fontSize='20' />{" "}<TimeAgo milliSeconds={response.date}></TimeAgo></Typography>
                            <Typography component={'div'} variant='caption' mr={2}><PersonOutlineIcon fontSize='20' />{" " + response.userName}</Typography>
                        </div>
                        <div className='d-flex'>
                            <ResponseLikeUnlike response={response} onLikeUnlike={getResponse} />
                            <IconButton onClick={share} size='small'>
                                <ShareIcon fontSize='small'></ShareIcon>
                            </IconButton>
                            <IconButton onClick={deleteRes} size='small'>
                                <DeleteOutlineOutlinedIcon fontSize='small'></DeleteOutlineOutlinedIcon>
                            </IconButton>
                        </div>
                    </div>
                }
                component='div'
                className='p-2'
            />
            <CardContent className='p-2 pt-0'>
                <Box sx={{ display: 'flex' }}>
                    <RichTextEditorReadOnly rawContent={response.response}></RichTextEditorReadOnly>
                    {currentUser && (currentUser.uid === response.userId || adminUser) &&
                        <ResponseEdit response={response} onUpdate={setResponse} />}
                </Box>
                <Comments commentsFor="response" commentsForObj={response}></Comments>
            </CardContent>
        </Card>
    )
}

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import AppRouter from './AppRouter';
import Header from './Header';
import AppMenu from './AppMenu';
import { SwipeableDrawer } from '@mui/material';

const drawerWidth = 180;

function App(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                color=''
                sx={{
                    zIndex: { md: 1300 },

                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 0, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Header></Header>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ maxWidth: { md: drawerWidth }, flexShrink: { md: 0 } }}
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <SwipeableDrawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onOpen={handleDrawerToggle}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '220px' },
                    }}
                >
                    <Toolbar sx={{justifyContent:'center'}}><img height={'60px'} src='/rnf.svg'></img></Toolbar>
                    <AppMenu></AppMenu>
                </SwipeableDrawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    <Toolbar />
                    <AppMenu></AppMenu>
                </Drawer>
            </Box>

            <Box
                component="main"
                sx={{ p: 2, width: { md: `calc(100% - ${drawerWidth}px)`,xs:'100%' }, ml: { md: `${drawerWidth}px` } }}
            >
                <Toolbar />
                <AppRouter></AppRouter>
            </Box>

        </Box>
    );
}

export default App;
import React, { useEffect } from 'react'

export default function Tags() {

  useEffect(()=>{
    document.title = 'Tags - Rule not found';
  },[])

  return (
    <div>Tags coming soon...</div>
  )
}

import React, { useRef, useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

export default function Login(props) {
    const replaceWith = props.replaceWith;
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login, currentUser } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            setError("");
            setLoading(true);
            const loginStatus = await login(emailRef.current.value, passwordRef.current.value);
            if(loginStatus === 'success')           
                replaceWith('welcome');
            if(loginStatus === 'unverified')
                replaceWith('verify');
        } catch (e) {
            setError(e.message.replace('Firebase: ',''));
        }
        setLoading(false);
    }

    return (
        <div className='d-flex align-items-center justify-content-center w-100'>
            <div className='w-100' style={{ maxWidth: "400px" }}>
                <Card>
                    <Card.Body>
                        <h2 className='text-center mb-4'>Log In</h2>
                        {currentUser && currentUser.email}
                        {error && <Alert variant='danger'>{error}</Alert>}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group id="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" ref={emailRef} required />
                            </Form.Group>
                            <Form.Group id="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" ref={passwordRef} required />
                            </Form.Group>
                            <Button disabled={loading} className="w-100 mt-4" type="submit">Log In</Button>
                        </Form>
                    </Card.Body>
                    <div className='w-100 text-center mb-2'>
                        <Link onClick={()=>{replaceWith('forgotPassword')}}>Forgot Password</Link>
                    </div>
                </Card>
                <div className='w-100 text-center mt-2'>
                    Don't have an account ? <Link onClick={()=>{replaceWith('signup')}}>Sign Up</Link>
                </div>
            </div>
        </div>
    )
}
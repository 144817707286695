import React, { useContext, useEffect, useState } from 'react'
import { auth } from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, updateProfile, sendEmailVerification } from 'firebase/auth';
import { AppData } from '../data/AppData';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const DataAPI = AppData.getDataAPI();
    const [adminUser, setAdminUser] = useState(false);

    function signup(emailId, password, name) {
        return createUserWithEmailAndPassword(auth, emailId, password).then(async (userCredentials) => {
            await updateProfile(userCredentials.user, { displayName: name });
            await DataAPI.addUser(userCredentials.user.uid, name, emailId);
            await signOut(auth);
        })
    }

    function login(emailId, password) {
        return signInWithEmailAndPassword(auth, emailId, password).then(async (userCredentials)=>{
            if(userCredentials.user && userCredentials.user.emailVerified){
                return 'success';
            }else{
                await signOut(auth);
                return 'unverified';
            }
        })
    }

    function logout() {
        return signOut(auth);
    }

    function resetPassword(email) {
        return sendPasswordResetEmail(auth, email);
    }

    useEffect(() => {
        try {
            const unsubscribe = auth.onAuthStateChanged(user => {
                if(user && user.emailVerified !== true){
                    sendEmailVerification(user);
                    //signOut(auth);
                    return;
                }
                setCurrentUser(user);
                if (user)
                    DataAPI.isAdminUser(user.uid).then((res) => {
                        setAdminUser(res);
                    })
            });
            return unsubscribe;
        } catch (e) {
            console.log(e);
        }
    }, []
    )

    const value = {
        currentUser,
        signup,
        login,
        logout,
        resetPassword,
        DataAPI,
        adminUser
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}


import { IconButton, InputLabel, List, ListItem, ListItemText, ListSubheader, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useAuth } from '../../context/AuthContext';
import TimeAgo from '../Utilities/TimeAgo';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

export default function Comments(props) {
    const commentsFor = props.commentsFor;
    const commentsForObj = props.commentsForObj;
    const { DataAPI, currentUser, adminUser } = useAuth();
    const [comments, setComments] = useState(commentsForObj.comments ? commentsForObj.comments : []);
    const comment = useRef();
    const [commentError, setCommentError] = useState();

    useEffect(() => {
        /* if (!comments || comments.length < 1) {
            getComments();
        } */
        // commented above as comments are already passed from commentsForObj.comments
    }, [])

    function getComments() {
        try {
            if (commentsFor === 'question') {
                DataAPI.getComments_Question(commentsForObj.id).then((res) => {
                    setComments(res);
                });
            }
            if (commentsFor === 'response') {
                DataAPI.getComments_Response(commentsForObj.questionId, commentsForObj.id).then((res) => {
                    setComments(res);
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function onComment(e) {
        e.preventDefault();
        if (!isValidComment(comment.current.value)) {
            return;
        }
        try {
            const commentObj = {
                comment: comment.current.value,
                userId: currentUser.uid,
                userName: currentUser.displayName,
                date: (new Date()).getTime()
            }
            if (commentsFor === 'question') {
                await DataAPI.addComment_Question(commentsForObj.id, commentObj);
            }
            if (commentsFor === 'response') {
                await DataAPI.addComment_Response(commentsForObj.questionId, commentsForObj.id, commentObj);
            }
            getComments();
            comment.current.value = '';
            comment.current.blur();
        } catch (expn) {
            console.log(expn);
        }
    }

    function isValidComment(cmt) {
        setCommentError(null);
        if (!cmt || cmt === "")
            return false;
        if (cmt.length > process.env.REACT_APP_COMMENT_LENGTH) {
            setCommentError('Max length allowed is ' + process.env.REACT_APP_COMMENT_LENGTH + " chars");
            return false;
        }
        return true;
    }

    async function deleteComment(e) {
        if (!window.confirm('are you sure, you want to delete the comment?'))
            return;
        try {
            if (commentsFor === 'question')
                await DataAPI.deleteQComment(commentsForObj.id, e);
            if (commentsFor === 'response')
                await DataAPI.deleteRComment(commentsForObj.questionId, commentsForObj.id, e);
            const newCmts = [...comments];
            const index = newCmts.indexOf(e);
            newCmts.splice(index, 1);
            setComments(newCmts);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <List subheader={<ListSubheader sx={{ padding: 0, lineHeight: '20px', marginTop: 2 }} style={{ position: 'inherit' }}>Comments</ListSubheader>}>

            {comments && comments.length > 0 ?
                comments.map((element) => {
                    return <ListItem sx={{ padding: "0 0 0 8px" }} key={element.date}>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <ChatBubbleOutlineIcon fontSize='16px' style={{ marginRight: 4 }} />
                                    {element.comment}
                                    <Typography variant='caption' component={"span"} color={"text.primary"}> -- {element.userName}</Typography>
                                    <Typography variant='caption' component={"span"} color={"text.primary"}>, <TimeAgo milliSeconds={element.date} /> </Typography>
                                    {currentUser && (currentUser.uid === element.userId || adminUser) &&
                                        <IconButton onClick={() => { deleteComment(element) }} size='small'><DeleteOutlinedIcon fontSize='inherit' /></IconButton>}
                                </React.Fragment>
                            }
                            sx={{}}
                        />
                    </ListItem>
                })
                : <Typography variant='caption' pl={1}>None</Typography>
            }

            <ListItem className='flex-column' sx={{ padding: "0 0 0 8px" }}>
                {commentError ?
                    <Typography component={'div'} variant='caption' color={'red'}>{commentError}</Typography>
                    : ""}
                <form onSubmit={onComment} style={{ width: '100%' }}>
                    {currentUser && (!comments || comments.length < process.env.REACT_APP_COMMENTS_LIMIT) ?
                        <Typography
                            component={'input'}
                            variant='caption'
                            type='text'
                            placeholder={' Add a comment'}
                            style={{ border: '1px gray dotted', width: '130px', borderRadius: '10px' }}
                            ref={comment}
                            className='comment-custom'
                            maxLength={process.env.REACT_APP_COMMENT_LENGTH}
                        ></Typography>
                        : (!currentUser ?
                            <Typography component={InputLabel} variant='caption'>Login to comment</Typography>
                            : <Typography component={InputLabel} variant='caption'>Max comments limit reached</Typography>
                        )
                    }
                </form>
            </ListItem>
        </List>
    )
}

import React from 'react';
import GetQuestions from './Question/QList/GetQuestions';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';


export default function RightPanel() {

    return (
        <Box sx={{ paddingLeft: 2, maxWidth: '600px', minWidth: '250px', width: '31%', display: { sm: 'block', xs: 'none' } }}>

            <Card variant='outlined' sx={{}}>
                <CardHeader
                    sx={{ padding: 1, background: 'darkgray' }}
                    title={
                        <Typography variant='body1'>Top questions</Typography>
                    }
                />
                <CardContent sx={{ paddingY: 0, paddingX: 1, maxHeight: '450px', overflow: 'auto' }}>
                    <GetQuestions category='top' displayType='list' />
                </CardContent>
            </Card>
        </Box>
    )
}

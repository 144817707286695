// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDQJJZgnWrae84EPM9PbFTnBt_-ZRdUEPE",
    authDomain: "rnflikes-ef219.firebaseapp.com",
    projectId: "rnflikes-ef219",
    storageBucket: "rnflikes-ef219.appspot.com",
    messagingSenderId: "474618505180",
    appId: "1:474618505180:web:19356acf64dc277fd29119",
    measurementId: "G-92YYYK8BX9"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig,"likes");
export const likesDB = getFirestore(app);
import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Shorticles from './Shorticles'
import { Box, IconButton, SwipeableDrawer, Toolbar } from '@mui/material'
import AppMenu from './AppMenu'
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../context/AuthContext'
import AccountMenu from './Authenticate/AccountMenu'

export default function AppWithoutHeader() {
    const [openNav, setOpenNav] = useState(false);
    const {currentUser} = useAuth();

    const leftNav =
        <SwipeableDrawer
            anchor={'left'}
            open={openNav}
            onClose={() => { setOpenNav(false) }}
            onOpen={() => { setOpenNav(true) }}
            sx={{ display: { sm: 'none' }, width: '220px', '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '220px' } }}
        >
            <Toolbar sx={{ justifyContent: 'center' }}><img height={'60px'} src='/rnf.svg'></img></Toolbar>
            <AppMenu></AppMenu>
        </SwipeableDrawer>

    const menuButton =
        <IconButton
            onClick={() => { setOpenNav(true) }}
            size='small'
            sx={{ position: 'fixed', top: 16, right: 16, display: { sm: 'none' }, bgcolor: 'rgba(0, 0, 0, 0.04)' }}
        >
            <MenuIcon />
        </IconButton>

    return (
        <Box sx={{ display: 'flex' }}>
            <Box
                sx={{ display: { xs: 'none', sm: 'block', borderRight: '1px solid rgba(0, 0, 0, 0.12)' } }}>
                <Toolbar sx={{ justifyContent: 'center' }}>
                    {//currentUser && <AccountMenu/>
                    }
                    <img height={'60px'} src='/rnf.svg'></img>
                </Toolbar>
                <AppMenu></AppMenu>
            </Box>
            <Box flex={1}>
                <Routes>
                    <Route path="/" Component={Shorticles}></Route>
                    <Route path="/:shorticleId" Component={Shorticles}></Route>
                </Routes>
            </Box>
            {leftNav}
            {menuButton}
        </Box>
    )
}


export const tags =
[
    'Application',
'ApplicationDocument',
'ApplicationGuide',
'Requirement',
'Specification',
'Association',
'DataFlow',
'DataPage',
'DataSet',
'DataTransform',
'EditInput',
'EditValidate',
'FieldValue',
'Property',
'PropertyAlias',
'PropertyQualifier',
'AdaptiveModel',
'CaseMatch',
'Collection',
'Constraints',
'DecisionData',
'DecisionTable',
'DecisionTree',
'DeclareExpression',
'DeclareOnChange',
'DeclareTrigger',
'EventStrategy',
'MapValue',
'PredictiveModel',
'PropositionFilter',
'Scorecard',
'Strategy',
'TextAnalyzer',
'When',
'ConnectCassandra',
'ConnectCMIS',
'ConnectdotNet',
'ConnectEJB',
'ConnectFile',
'ConnectHBase',
'ConnectHTTP',
'ConnectJava',
'ConnectJCA',
'ConnectJMS',
'ConnectMQ',
'ConnectREST',
'ConnectRobot',
'ConnectSAP',
'ConnectSAPJCo',
'ConnectSOAP',
'ConnectSQL',
'EFormFile',
'MapeForm',
'MapStructured',
'ParseDelimited',
'ParseNormalize',
'ParseStructured',
'ParseXML',
'XMLStream',
'AtomServer',
'ConnectRequestProcessor',
'EmailAccount',
'EmailListener',
'FileListener',
'FTPServer',
'IntegrationSystem',
'JCAResourceAdapter',
'JMSListener',
'JMSMDBListener',
'JMSProducerModel',
'JMSTransport',
'JNDIServer',
'MQListener',
'MQServer',
'ServicePackage',
'ServiceRequestProcessor',
'SMSAccount',
'SOAPServer',
'WebServicePolicy',
'ServicedotNet',
'ServiceEJB',
'ServiceEmail',
'ServiceFile',
'ServiceHTTP',
'ServiceJava',
'ServiceJMS',
'ServiceJSR94',
'ServiceMQ',
'ServiceREST',
'ServiceSAP',
'ServiceSAPJCo',
'ServiceSOAP',
'Calendar',
'Division',
'OperatorID',
'Organization',
'OrganizationUnit',
'WorkGroup',
'Workqueue',
'AttachmentCategory',
'CaseType',
'Correspondence',
'CorrespondenceFragment',
'CorrespondenceType',
'Flow',
'FlowAction',
'Notification',
'PulseFeed',
'ServiceLevelAgreement',
'Skill',
'Stage',
'Ticket',
'Validate',
'WorkParties',
'Reportdefinition',
'Shortcut',
'AccessControlPolicy',
'AccessControlPolicyCondition',
'AccessDeny',
'AccessGroup',
'AccessofRoletoObject',
'AccessRoleName',
'AccessWhen',
'ApplicationID',
'AuthenticationProfile',
'ClientBasedAccess',
'ContentSecurityPolicy',
'CrossOriginResourceSharing',
'IdentityMapping',
'Keystore',
'OAuth2.0ClientRegistration',
'OAuth2.0Provider',
'OAuthClient',
'Privilege',
'PropertySecurity',
'RuleAnalyzerRegularExpression',
'Setting',
'TokenProfile',
'WS-SecurityProfile',
'ComplexQuestion',
'QuestionPage',
'SimpleQuestion',
'AgentSchedule',
'Agents',
'ApplicationSettings',
'AuthenticationService',
'Category',
'Class',
'ClassGroup',
'CustomSearchProperties',
'Database',
'DatabaseTable',
'DeclareIndex',
'DistinctValueIndex',
'DynamicSystemSettings',
'ExcelTemplate',
'FlowMarker',
'Hadoop',
'JobScheduler',
'Kafka',
'License',
'LogCategory',
'OLAPConnection',
'Product',
'ProductPatch',
'QueueProcessor',
'RemoteSystem',
'Repository',
'RequestorType',
'RuleSet',
'SpellCheckerProperties',
'System',
'SystemNode',
'WordTemplate',
'Activity',
'BatchProcess',
'BinaryFile',
'Broadcast',
'CircumstanceDefinition',
'CircumstanceTemplate',
'Function',
'FunctionAlias',
'ImageContent',
'Library',
'List',
'Message',
'ScenarioTestSuite',
'StaticContentBundle',
'Stencil',
'TestSuite',
'TextFile',
'URLMappings',
'Control',
'GuidedTour',
'Harness',
'Navigation',
'Paragraph',
'Portal',
'Section',
'Skin',
'Java',
'Html',
'Css',
'Javascript',
'JSP',
'XML',
'SQL',
'ReactJS',
'Other'
]

import React, { useState } from 'react'
import Login from './Login'
import { Box, Typography } from '@mui/material';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';
import UserProfile from './UserProfile';
import { Link } from 'react-router-dom';

export default function UserAccount(props) {
  const displayComponent = props.displayComponent;
  const [displayScreen, setDisplayScreen] = useState(displayComponent);
  return (
    displayScreen === 'login' ?
      <Login replaceWith={setDisplayScreen} />
      : displayScreen === 'signup' ?
        <Signup replaceWith={setDisplayScreen} />
        : displayScreen === 'forgotPassword' ?
          <ForgotPassword replaceWith={setDisplayScreen} />
          : displayScreen === 'userProfile' ?
            <UserProfile replaceWith={setDisplayScreen} />
            : displayScreen === 'welcome_new' ?
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box>
                  <Typography variant='h5'>Thank you for signing up</Typography>
                  <Typography variant='body'>An email with verification link is sent, please verify your email address and <Link onClick={()=>{setDisplayScreen('login')}}>log-in</Link></Typography>
                  <Typography variant='body2'>If you don't see the email in your inbox, please check if it's routed to spam</Typography>
                </Box>
              </Box>
              : displayScreen === 'verify' ?
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box>
                    <Typography variant='h5'>Your email address not verified</Typography>
                    <Typography variant='body'>An email with verification link is sent, please verify your email address and <Link onClick={()=>{setDisplayScreen('login')}}>log-in</Link></Typography>
                    <Typography variant='body2'>If you don't see the email in your inbox, please check if it's routed to spam</Typography>
                  </Box>
                </Box>
                : <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant='h2'>Welcome</Typography>
                </Box>
  )
}
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { Typography, IconButton } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

export default function ResponseLikeUnlike(props) {
    const questionId = props.response.questionId;
    const responseId = props.response.id;
    const [likesCount, setLikesCount] = useState(props.response.likes > 0 ? props.response.likes : 0);
    //const onLikeUnlike = props.onLikeUnlike;
    const { DataAPI, currentUser } = useAuth();
    const [isLiked, setIsLiked] = useState(false);
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        if (currentUser) {
            DataAPI.IsResponseLikedByUser(questionId, responseId, currentUser.uid).then((res) => {
                setIsLiked(res);
            });
        }
    }, [])

    function onClickLikeUnlike() {
        try {
            setDisable(true);
            if (isLiked) {
                DataAPI.unLikeResponse(questionId, responseId, currentUser.uid).then(() => {
                    setIsLiked(false);
                    //onLikeUnlike();
                    setLikesCount(likesCount - 1);
                    setDisable(false);
                })

            } else {
                DataAPI.likeResponse(questionId, responseId, currentUser.uid).then(() => {
                    setIsLiked(true);
                    //onLikeUnlike();
                    setLikesCount(likesCount + 1);
                    setDisable(false);
                })
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <IconButton size='small' onClick={onClickLikeUnlike} disabled={currentUser ? (disable?true:false) : true}>
            {isLiked ?
                <ThumbUpIcon fontSize='small'></ThumbUpIcon>
                : <ThumbUpOffAltIcon fontSize='small'></ThumbUpOffAltIcon>
            }
            <Typography variant='caption' ml={0.5}>{likesCount}</Typography>
        </IconButton>
    )
}
